.article {
  @extend %sub-header-text;

  body {
    position: relative;
  }
  a, .blue-color{
    color: $dark-blue;
  }
  .green-color {
    color: $dark-green;
  }
  .header {
    .trix-content {
      color: $dark-blue;
    }
  }
  @include media-breakpoint-down(sm) {
    article {
      order: 2;
    }
    .nav {
      padding-bottom: 1.5rem;
    }
  }
  .trix-content {
    color: $gray-text;
    h2 {
      font-family: $sf-pro;
      font-weight: 600;
    }
    h2::before {
      counter-increment: section;
      content: counter(section) " ";
      color: #37407b;
      background-color: $light-yellow;
      padding: 2rem 1.1rem;
      float: left;
      margin-right: 1rem;
    }
    h3 {
      font-weight: 600;
    }
    .attachment--preview {
      text-align: unset;
    }
  }
  ul.nav-pills {
    position: sticky;
    top: 0;
  }
  .nav-pills .nav-link {
    background-color: $light-yellow;
    color: $violet;
    border-bottom: solid 0.5px $violet;
    border-radius: 0;
  }
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: white;
    background-color: $violet;
  }
}
