.navbar {
  padding: 1rem 1rem;
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 100, 104, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  }
  .navbar-toggler {
    border-color: #E8F4F2;
  }
}
.thanks {
  @extend %sub-header-text;
  .logo-bg {
    padding-top: 10rem;
    padding-bottom: 10rem;
    background-image: url('../images/www/bg-logo.png');
    background-size: cover;
  }
  @include media-breakpoint-down(md) {
    .buttons {
      .btn {
        width: 80% !important;
      }
    }
  }
}

.btn {
  font-size: 1rem;
  @include media-breakpoint-down(md) {
    width: 100% !important;
  }
}

.logo-image {
  max-width: 100%;
  max-height: 100%;
}

figcaption {
  display: none;
}

.carousel-indicators {
  bottom: -45px;
  li {
    background-color: $mon-ami-mint;
    height: 0.5rem;
  }
}

.pitch {
  .p-25 {
    padding: 0.75rem;
  }

  .row .col-md-6 {
    @include media-breakpoint-up(sm) {
      padding: 0 3rem;
    }
  }
  i.fas {
    padding-top: inherit;
  }
}

.terms-of-use,
.privacy-policy {
  @extend %sub-header-text;
  .padding {
    padding: 0 10rem 0 10rem;
    @include media-breakpoint-down(sm) {
      padding: 3rem;
    }
  }
  .fw-bold {
    color: $mon-ami-mint;
  }
  .text {
    color: $gray-text;
  }
  ul {
    list-style-position: outside;
  }
}

.jobs {
  .headdy {
    background-color: $light-violet;
  }
}

.faq #accordion {
  border-bottom: 2px solid $violet;

  button {
    text-align: left;
    padding: 1rem 2rem 1rem 15px;
    border-top: 2px solid $violet;
    position: relative;
    color: $mon-ami-mint;
    font-size: 1.6rem;
    font-weight: 600;

    @include media-breakpoint-only(xs) {
      font-size: 1.1rem;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &:after {
      @extend .color-frosting;
      content: '-';
      position: absolute;
      right: 0;
      font-size: 3rem;
      top: 0;
      font-weight: 100;
    }

    &.collapsed {
      &:after {
        @extend .color-monami-mint;
        content: '+';
        font-size: 2rem;
      }
    }
  }
}

.companion-carousel {
  img {
    max-width: 70%;
    margin: 0 auto;
  }
  .box {
    min-height: 13rem;
  }
  .content {
    font-size: 1rem;
  }
}

.logo-carousel {
  img {
    max-height: 5rem;
    max-width: 20rem;
  }
  .carousel-item {
    height: 8rem;
    width: 100%;
    text-align: center;
  }
}

.contact-us {
  @extend %sub-header-text;
  .intro {
    background-image: url('../images/www/bg-signup-light-purple.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .wide-lead-form{
    background: unset;
    label {
      color: $mon-ami-mint;
    }
    textarea {
      height: 10rem;
    }
  }
  .areas {
    .flex {
      flex-flow: wrap;
      @include media-breakpoint-down(sm) {
        align-items: center;
        justify-content: center;
      }
    }
    .city {
      color: #626fc7;
      text-decoration: underline;
      margin-left: 0.2rem;
      margin-right: 0.2rem;
    }
  }
}

.sign-up {
  @extend %sub-header-text;
  .intro {
    background-image: url('../images/www/light-purple.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .wide-lead-form {
    @include media-breakpoint-up(xs) {
      .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .testimonial {
    .by {
      color: #293ecb;
    }
    img {
      width: 10rem;
    }
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
}

.service {
  @extend %sub-header-text;
  .primary-photo {
    transform: rotate(-2deg);
  }
}

.covid-19 {
  @extend %sub-header-text;

  .intro {
    background-color: #fde7e0;
  }

  .link {
    color: #626fc7;
  }

  .image {
    max-width: 100%;
  }
}

.volunteer-management {
  @extend %sub-header-text;
  .image {
    max-width: 100%;
  }
  .link {
    a {
      color: $mon-ami-mint;
    }
  }
}
