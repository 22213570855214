.software-program {
  .hero {
    @extend %sub-header-text;

    h1 {
      color: #5364ae
    }
    img {
      width: 100%;
    }
  }

  .what-we-offer {
    max-width: 100%;
  }

  .our-solution {
    img {
      width: 100px;
      height: 100px;
    }

    .carousel-controls {
      img {
        width: 35px;
        height: 35px;
      }
    }
  }

  .testimonial {
    img {
      border-radius: 50%;
      width: 20rem;
    }
  }

  .work-in-action {
    .card-body {
      background-color: #eaecf6;
      height: 120px;
    }
  }
}
