$dark-green: #466A72;
$darker-green: #19434C;
$wild-sand: #f5f5f5;
$link-hover-color: #4a90e2;
$select-date-color: #495057;
$ghost: #CED4DA;
$light-green: #EDF6F6;
$light-orange: #FFE4D8;
$silver: #BBBBBB;
$mustard: #D38764;
$new-jungle: #08585B;
$light-orange: #FAAC89;
$header-height: 80px;
$corn-blue: #F8FAFA;
$btn-hover-color: #d4d8f2;

.select-date select {
  width: 32%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $select-date-color;
  background-color: white;
  border: 1px solid $ghost;
  border-radius: .25rem;
}

.cursor-pointer {
  cursor: pointer;
}

.header {
  .header-padding {
    padding: 42px;
    height: 128px;
  }

  h1 {
    font-family: 'recoleta-regular';
    font-weight: 500;
    font-size: 2.75rem;
    @include media-breakpoint-down(sm) {
      font-size: 0.75rem;
      letter-spacing: 0;
    }
  }

  .org-name {
    font-family: 'recoleta-regular';
    font-size: 1.3125rem;
    color: $dark-green;
    opacity: 0.8;
    @include media-breakpoint-down(sm) {
      font-size: 21px;
      margin-left: 0;
      margin-top: 0;
    }
  }

  .org-logo {
    max-height: 41px;
    width: auto;
  }

  .org-image {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.attendance-cancelation {
  .btn {
    width: 350px
  }
  .btn-secondary {
    border: 2px solid $primary;
    color: $primary;
    background-color: white;
    &:hover {
      color: white;
      background-color: $btn-hover-color;
      border-color: $btn-hover-color;
    }
  }
}

.categories-title {
  color: $black;
  font-family: 'recoleta-regular';
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
}

.filter-label {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  color: $dark-green;
  margin-left: 14px;
}

.background-hover {
  &:hover {
    background-color: $wild-sand;
  }
}

.dropdown-placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: black;
  height: 40px;
  width: 203px;
}

@media screen and (max-width: $md-width) {
  .event-title-container {
    padding: 1.5rem 0rem;
  }
}

.printable {
  font-size: 1rem;

  h1 {
    color: black;
    font-size: 1.875rem;
  }

  h2 {
    color: black;
    font-size: 1.25rem;

  }

  .fc-scrollgrid {
    border-left: 1px solid #ddd !important;
  }

  a {
    color: $primary;
  }

  .description {
    font-size: 1rem;
    font-weight: 500;

    .trix-content {
      line-height: 1.5rem;
    }
  }

  .datetime {
    font-size: 0.8125rem;
    line-height: 1.25rem;
  }

  .btn-primary {
    color: white;

    &:hover {
      color: $primary;
    }
  }

  .btn-onlycalendar {
    font-weight: 600;
    color: $danger;
    border: 2px solid $danger;
  }

  .printable-calendar {
    .fc-daygrid-day-frame {
      min-height: 80px !important;
    }
  }

  @media print {
    .calendar {
      display: none;
    }
  }

  .page-break-avoid {
    page-break-inside: avoid
  }

}

#calendar {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: black;
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$header-height});

  .org-name {
    text-overflow: ellipsis;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
  }

  .fc-daygrid-day-frame {
    min-height: 200px;
  }

  .fc-day-other {
    background-color: $corn-blue;

  }

  .fc-col-header-cell-cushion {
    text-transform: uppercase;
    font-size: 0.875rem;
    padding: 8px;
    font-weight: 500;
    &:hover {
      text-decoration: none;
    }
  }

  @media screen and (min-width: $md-width) {
    .fc-header-toolbar {
      padding: 1rem;
      height: $header-height;
      margin-bottom: 0;
    }

    .fc-scrollgrid {
      border: none;
      border-top: 1px solid #ddd;
    }


    h2.fc-toolbar-title {
      color: #000;
      letter-spacing: 0;
      font-family: '-apple-system', 'BlinkMacSystemFont', 'SF Pro', 'Arial', 'Helvetica', 'sans-serif';
      font-weight: 700;
      font-size: 2rem;
    }

    .fc-next-button {
      border-left: none;
    }

    .fc-prev-button {
      border-right: none;
    }

    .fc-daygrid-event-dot, .fc-list-event-dot {
      display: none;
    }
  }

  a {
    color: inherit;
    cursor: pointer;
  }

  .fc-button {
    outline: none !important;
    text-transform: none;

    &:hover {
      background-color: $wild-sand;
      color: $darker-green;
      cursor: pointer;
    }
  }

  .fc-button-active {
    background-color: $mon-ami-mint;
    color: white;
  }

  button, .fc-v-event .fc-event-main-frame {
    background-color: #fff;
    border-color: $placeholder;
    color: black;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    border-radius: 5px;
  }

  .fc-event-title {
    white-space: normal;
    font-size: 1rem;
    font-weight: normal;
    overflow-wrap: break-word;
    line-height: 20px;
    margin: 8px 4px 8px 4px;
    color: black;

    // truncate title to three lines
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }

  .fc-daygrid-day-number {
    font-size: 1rem;
    &:hover {
      text-decoration: none;
    }
  }

  .fc-day-today.fc-day-other {
    background-color: $corn-blue;
  }

  .fc-day-today {
    background-color : white;

    .fc-daygrid-day-number {
      width: 30px;
      height: 30px;
      padding: 0px;
      border-radius: 50%;
      text-align: center;
      border: 2px solid white;
      background-color: $mon-ami-mint;
      color: white;
    }
  }

  .fc-list {
    width: 66.66%;
    margin-left: auto;
    margin-right: auto;
  }

  .fc-view-harness.fc-view-harness-passive {
    @extend .px-0;
  }

  .fc-list.fc-list-sticky.fc-listWeek-view.fc-view {
    @extend .container-sm;
    @extend .px-0;
  }

  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    @extend .mb-0;
    background-color: white;
  }

  .fc-list-day.fc-day-today {
    .fc-list-day-text {
      width: 90px;
      text-align: center;
      border: 2px solid white;
      background-color: $mon-ami-mint;
      color: white;
    }
  }
  .fc-list-event.fc-event-today {
    background-color: white;
  }

  .fc-daygrid-more-link {
    font-weight: bold;
    font-size: 14px;
    line-height: 36px;
    color: $primary;
    margin-left: 4px;
  }

  .fc-daygrid-event {
    background-color: $light-green;
    margin: 0 0.5rem 0.5rem 0.5rem;
    border-color: $light-green;

    &.status-canceled {
      background-color: $light-orange;
    }

    &:hover {
      outline: 1px solid #6BAEAE;
    }
  }

  .fc-list-event-graphic {
    display: none;
  }

  .fc-dayGridMonth-button, .fc-dayGridWeek-button, .fc-timeGrid-button, .fc-listWeek-button, .fc-today-button {
    height: 40px;
    @media screen and (min-width: $lg-width) {
      width: 6rem;
    }
  }

  .fc-dayGridMonth-button {
    border-radius: 5px 0px 0px 5px;
  }

  .fc-listWeek-button {
    border-radius: 0px 5px 5px 0px;
  }

  .fc-next-button, .fc-prev-button {
    width: 3rem;
    height: 3rem;
    border: none;
    margin-right: 8px;
  }

  .fc-icon {
    font-size: 2rem;
  }

  @media screen and (max-width: $md-width) {
    padding-top: 0;
    .fc-button {
      height: 3.75rem;
    }

    .fc-toolbar-chunk {
      .fc-button {
        border-top: none;
        border-bottom: none;
      }
    }

    .fc-toolbar-title {
      font-size: 1.125rem;
      padding: 1rem;
      padding-top: 20px;
      text-align: center;
      background: white;
    }

    .fc-toolbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      top: 0;
      z-index: 1;
      border-top: 1px solid #d9dcec;
      border-bottom: 1px solid #d9dcec;
    }

    .fc-view-harness {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.event-details {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: black;
  font-size: 1rem;

  a {
    &:hover {
      text-decoration: none !important;
    }
  }

  .back-to-calendar {
    border: 1px solid $placeholder;
    border-radius: 20px;
    &:hover {
      background-color: $wild-sand;
    }
  }

  h1 {
    letter-spacing: 0;
    font-family: '-apple-system', 'BlinkMacSystemFont', 'SF Pro', 'Arial', 'Helvetica', 'sans-serif';
    font-weight: 700;
    font-size: 2rem;
    color: black;
  }

  .event-image {
    max-height: 18rem;
  }

  h4 {
    color: black;
  }

  .close {
    color: $violet;
  }

  .back-text {
    color: #000
  }

  a {
    color: $violet;
  }

  .dot {
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }

  .activity-dot {
    background-color: #a4afec
  }

  .meeting-dot {
    background-color: #fd9ace
  }

  .form-col {
    overflow-y: auto;
    @media screen and (min-width: $md-width) {
      background-color: $sherbert;
    }
  }

  .col-height {
    height: 100vh;
  }

  .attendee-form {

    abbr {
      display: none;
    }

    .form-group {
      margin-bottom: 1.75rem;
    }

    label {
      font-weight: normal;
      color: black;
      line-height: 1.25rem;
    }

    .invalid-feedback {
      font-size: 100%;
    }
  }

  .attendee-form-link {
    padding: 0.3rem;
    border: 1px solid gray;
  }

  .register-btn {
    border-radius: 55px;
  }

  ::placeholder {
    color: $silver;
    font-size: 1.125rem;
  }
}

.event-confirmation {
  h4 {
    color: $new-jungle;
  }
  h3 {
    font-size: 1.875rem;
  }
  .btn-primary {
    border-radius: 35px;
  }
  .alert-box {
    border: 2px solid $light-orange;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .registered {
    color: $new-jungle;
    img {
      width: 24px;
    }
  }
  .table td {
    border-bottom: 1px solid #dee2e6;
    border-top: none;
  }
  .td-width {
    width: 16rem;
  }
}

.survey-response-thank-you {
  h2 {
    font-size: 1.875rem;

    @include media-breakpoint-down(sm) {
      font-size: 0.75rem;
    }
  }

  h4 {
    font-family: 'recoleta-regular';
    font-size: 1.25rem;
    font-weight: 400;

    @include media-breakpoint-down(sm) {
      font-size: 0.5rem;
    }
  }
}

.branding {
  .logo {
    margin: 0rem;
    width: 10rem;
    max-height: 10rem;
  }
}

.tag-box {
  padding: 2px 20px;
  font-size: 0.875rem;
  border-radius: 9px;
  height: 14.23px;
  border-radius: 2.66871px;
  text-align: center;
  display: table-cell;
  background-color: $light-orange;
  color: black;
}

.bg-egg-shell {
  background-color: #fbf9f4;
}

.event-radio-buttons {
  input[type='radio']:checked ~ label {
    font-weight: 500;
    color: black !important;
  }

  input[type='radio'] {
    height: 24px;
    width: 24px;
  }

  .form-check {
    margin-bottom: 1rem;
  }

  .collection_radio_buttons, .radio_buttons {
    &:hover {
      cursor: pointer;
    }
  }

  label {
    font-size: 16px !important;
    vertical-align: middle;
    margin: 0;
    color: black !important;
    text-transform: none !important;
    margin-left: 1rem;
  }

  .invalid-feedback {
    flex: 0 1 100%;
    font-weight: 600;
    font-size: 1rem;
  }
}

.scrollable-context {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .scrollable-container {
    overflow-y: auto;
    flex: 1;
  }
}
