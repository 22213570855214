.resolve-superform-errors {
  background: #F7928E;
  color: white;

  .line {
    width: 24px;
    height: 0px;
    border: 1px solid white;
    transform: rotate(90deg);
  }
}

.super-form-scrollable-container, .super-form-nav {
  // headers
  .fieldset {
    margin-left: 0;
    margin-right: 0;
    .legend {
      font-size: 1.25rem;
      padding-top: 1.5rem;
    }

    // subheaders
    .fieldset {
      .legend {
        text-transform: uppercase;
        font-size: 0.875rem;
        padding-bottom: 0.5rem;
      }

      hr {
        display: none;
      }
    }

    table.table-responsive {
      td {
        .input-group {
          flex-wrap: nowrap;
        }
      }
    }
  }
}
