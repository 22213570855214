@import './variables';

.navbar-expand-md .navbar-collapse {
  flex-basis: 100%;
}

.fixed-header {
  border-bottom: 1px solid #DDDDDD;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index:100;

  .logo {
    @include media-breakpoint-down(lg) {
      width: 104px;
    }
    width: 160px;
  }

  .nav-link {
    font-family: $sf-pro !important;
    color: #555 !important;
    font-size: 1rem !important;
    line-height: 1.25rem !important;
  }
}

.main-content {
  margin-top: 82px;
}

.poweredby {
  max-height: 28px;
  width: auto;
  @include media-breakpoint-down(sm) {
    max-height: 15px;
  }
}
