@import './variables';

html {
  scroll-behavior: smooth;
}

.homepage {
  h2 {
    @include media-breakpoint-up(lg) {
      line-height: 3.25rem;
    }

    @include media-breakpoint-down(md) {
      line-height: 2.5rem;
      font-size: 2rem !important
    }
  }

  h1, h2 {
    font-family: Recoleta-Medium;
    font-size: 40px;
    color: #000000;
  }

  p {
    font-family: $sf-pro;
    font-size: 18px;
    color: #000000;
  }

  div.anchor-tag {
    scroll-margin-top: 50px;
  }

  #whos-it-for, #client-database, #case-management, #volunteer-management, #evidence-based-programs,
  #insights-and-reporting, #federated-system, #meals-management,#ombudsman,#provider-management-billing,#information-referrals {
    p {
      @include media-breakpoint-up(lg) {
        font-size: 1.125rem;
        line-height: 1.75rem
      }

      @include media-breakpoint-down(md) {
        font-size: 1rem;
        line-height: 1.5rem
      }
    }
  }

  .gold-subheader {
    font-family: Recoleta-Medium;
    color: #C39900;

    @include media-breakpoint-up(xl) {
      line-height: 1.75rem;
    }
    font-size: 20px;
  }

  .tick-list {
    ul {
      list-style-position: outside;

      li {
        @include media-breakpoint-up(lg) {
          font-size: 1.125rem;
          line-height: 1.5rem;
        }

        @include media-breakpoint-down(md) {
          font-size: 1rem;
          line-height: 1.25rem;
        }

        font-family: $sf-pro;

        font-feature-settings: 'kern' off;
        padding-bottom: 0.75rem;
        color: #000000;
        list-style-image: url('../images/www/homepage/checkmark-bullet.svg');
      }
    }
  }

  .link-text {
    a {
      font-family: $sf-pro;
      font-weight: bold;
      font-size: 18px;
      color: #006468;
    }
  }

  .image-height {
    @include media-breakpoint-down(sm) {
      width:100%;
      height: auto;
    }
    height: 502px;
  }
  .img-80w {
      width:80%;
      height: auto;
  }
  .intro {
    h1 {
      @include media-breakpoint-up(xl) {
        width: 75%;
      }
    }

    p {
      @include media-breakpoint-up(xl) {
        width: 75%;
      }
    }
    .linkHeader {
      font-family: Recoleta-Medium;
      font-size: 16px;
    }

    .linkSubHeader {
      font-family: Recoleta-Medium;
      font-size: 16px;
      color: #4B8487;
    }

    .links {

      .jump-link {

        &:hover {
          span  {
            text-decoration: underline;
          }
        }

        .image {
          height: 45px;
          margin-bottom: 4px;
        }

        span  {
          font-family: $sf-pro;
          color: #000000;
          padding-left:10px;
          padding-right:10px;

          @include media-breakpoint-up(lg) {
            line-height: 1.25rem;
            font-size: 14px;
          }

          @include media-breakpoint-down(md) {
            line-height: 1rem;
            font-size: 0.8125rem;
          }
        }
      }
    }
  }


  #whos-it-for, #client-database, #case-management, #volunteer-management, #evidence-based-programs,
  #insights-and-reporting, #federated-system, #meals-management, #ombudsman, #provider-management-billing, #information-referrals {
    img {
      @include media-breakpoint-up(lg) {
        width: 75%;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-top: 24px;
      }
      height: auto;
    }
  }

  .learn-more {
    background-color: #EFF5F5;
  }

  .ready-to-start {
    background-color: #006468;

    h2 {
      color: white
    }
  }
}
