@import '../font-faces/recoleta';

%header-text {
  font-family: 'recoleta-regular';
  color: $mon-ami-mint;
  font-size: 3.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.04;

  @include media-breakpoint-down(md) {
    font-size: 3.125rem;
  }
}

%sub-header-text {
  font-family: $sf-pro;
  color: $mon-ami-mint;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;

  @include media-breakpoint-down(md) {
    font-size: 1.125rem;
  }
}

.recoleta-font-family {
  font-family: 'recoleta-regular';
}

.font-size-18 {
  font-size: 1.125rem
}

.sherbert-background-color {
  background-color: $sherbert;
}

.frosting-background-color {
  background-color: $frosting;
}

.toasted-sesame-background-color {
  background-color: $toasted-sesame;
}

.light-mint-background-color {
  background-color: $light-mint;
}

.light-violet-background-color {
  background-color: $light-violet;
}

.light-green-background-color {
  background-color: $light-green;
}

.mon-ami-mint-background-color {
  background-color: $mon-ami-mint;
}

.color-monami-mint {
  color: $mon-ami-mint;
}

.color-frosting {
  color: $frosting;
}

.color-violet {
  color: $violet;
}

.circular-icon {
  width: 50px;
  height: 50px;
  border-radius: 30px;
}

.p-25 {
  padding: 0.75rem;
}

.w-20 {
  width: 20%;
}

.w-60 {
  width: 60%;
}

.w-80 {
  width: 80%;
}

.rotate-y-180 {
  transform: rotateY(180deg);
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.center-vertically {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.fw-bold {
  font-weight: 600;
}

.upper-case {
  text-transform: uppercase;
}

body {
  font-family: $sf-pro;
  font-size: 1.1rem;
  counter-reset: section;

  h1,
  .trix-content h1 {
    @extend %header-text;
  }

  h2,
  .trix-content h2 {
    @extend %header-text;
    font-size: 3.125rem;
    @include media-breakpoint-down(md) {
      font-size: 2.5rem;
    }
  }

  h3 {
    color: $mon-ami-mint;
    font-size: 2.25rem;
    @include media-breakpoint-down(md) {
      font-size: 1.875rem;
    }
  }

  h4 {
    color: $mon-ami-mint;
    @include media-breakpoint-down(md) {
      font-size: 20px;
    }
  }
}

.navbar-light .navbar-nav {
  font-size: 18px;
  font-family: $sf-pro;

  a.nav-link,
  .active > a.nav-link {
    color: $mon-ami-mint;
    border-bottom: solid 4px rgba(255, 255, 255, 0);

    &:hover {
      color: $mon-ami-mint;
      border-bottom: solid 4px $light-yellow;
    }

    &:visited {
      color: $mon-ami-mint;
    }

    &.btn-primary {
      border-bottom: solid 4px rgba(255, 255, 255, 0);
      color: white !important;
      margin-left: 1rem !important;
      padding: {
        left: 1rem;
        right: 1rem;
      }
    }
  }
}

.btn {
  border-radius: 0;
  transition: all ease-in-out 0.25;
  &.btn-primary:hover {
    color: $primary;
    background-color: #d4d8f2;
    border-color: #d4d8f2;
  }
}

.call-icon {
  height: 1.2rem;
}

.logo {
  width: 70px;
  @include media-breakpoint-up(md) {
    width: 100px;
    padding: 0;
  }
  @include media-breakpoint-up(lg) {
    width: 180px;
    padding: 0;
  }
}

.covid-19-nav {
  background-color: #fde7e0;
}

.navbar-light .navbar-nav .nav-link {
  @include media-breakpoint-only(md) {
    font-size: 15px;
  }
}

@include media-breakpoint-down(sm) {
  .navbar {
    .order-2 {
      border-top: solid 1px #d9dcec;
    }
  }

  .navbar-light .navbar-nav a.nav-link, .navbar-light .navbar-nav .active > a.nav-link {
    padding: 0.5rem 1rem;
    border-bottom: solid 1px #d9dcec;
    &:hover {
      border-bottom: solid 1px #d9dcec;
    }
    &.btn-primary {
      margin-left: 0 !important;
      border-bottom: 0;
    }
  }
  .logo {
    margin: 1rem;
  }
}

.btn-group {
  width: 100%;

  > .btn:not(:first-child),
  > .btn-group:not(:first-child) {
    margin-left: unset;
  }

  .btn {
    border-left: 1px solid white;
    border-right: 1px solid white;
    flex-grow: 1;
    flex-basis: 0;
    &:first-child {
      margin-left: 0;
      border-left: 0;
    }
    &:last-child {
      margin-right: 0;
      border-right: 0;
    }
  }

  @include media-breakpoint-down(md) {
    display: grid;
    .btn {
      border: {
        top: 1px solid white;
        bottom: 1px solid white;
        left: 0;
        right: 0;
      }
    }
  }
}

.social {
  a,
  a:hover {
    text-decoration: none;
  }
  svg {
    color: $mon-ami-mint;
    height: 2rem;
    margin-right: 2rem;
  }
}

.monogram {
  width: 82px;
}

.btn-group-justified {
  a {
    color: white !important;
  }
}
.footer {
  @extend .toasted-sesame-background-color;
  padding: 3em;
  color: $mon-ami-mint;
  font-family: $sf-pro;
  font-size: 0.9rem;

  @include media-breakpoint-only(xs) {
    text-align: center;
  }

  .service-areas {
    margin-top: 1rem;

    ul {
      @include media-breakpoint-only(xs) {
        margin-bottom: 0;
      }
      li {
        list-style: none;
        font-weight: 300;
      }
    }
  }
  a {
    &:visited {
      color: $mon-ami-mint;
    }
  }
  .legal {
    a {
      text-decoration: underline;
      margin-left: 1rem;
    }
    .splainer {
      display: inline-block;
      width: 200px;
      margin-left: 1rem;
    }
    .links {
      padding-top: 1.7rem !important;
      @include media-breakpoint-only(xs) {
        a {
          float: none !important;
          display: block;
        }
      }
    }
  }
}

.wide-lead-form {
  background: url('../images/www/homepage/lead-bg.png');
  background-size: cover;
  color: white;

  h2 {
    color: white;
    @include media-breakpoint-up(lg) {
      width: 65%;
    }
  }

  form {
    input,
    select,
    option,
    textarea {
      border-radius: unset;
      color: $mon-ami-mint;
    }
  }
  .btn {
    color: white;
  }

  .form-control:focus, textarea:focus {
    border-color: $light-yellow;
    box-shadow: 0 0 0 0.2rem $light-yellow;
    outline: none;
  }
}

%companion-stories-carousel {
  .companion-stories-images {
    text-align: center;
    .trix-content {
      margin-left: auto;
      margin-right: auto;
      .attachment-gallery {
        .attachment {
          max-width: unset;
          margin-right: 1rem;
        }
      }
    }

    .attachment-gallery {
      action-text-attachment {
        width: 33%;
      }
    }

    img {
      border-radius: 50%;
    }

    @include media-breakpoint-down(sm) {
      .carousel-inner {
        margin-left: auto;
        margin-right: auto;
        width: 70%;
      }
      img {
        width: 10rem;
        height: auto;
      }
      ul {
        padding: 0;
      }
    }

    .trix-content div {
      @include media-breakpoint-up(md) {
        width: 60%;
        margin: {
          left: auto;
          right: auto;
        }
      }
    }
  }

  .companion-stories-text {
    ul {
      text-align: center;
      li {
        @extend %sub-header-text;
        font-size: 1.875rem;
        @include media-breakpoint-up(sm) {
          width: 70%;
          margin-left: auto;
          margin-right: auto;
        }
        &:first-child {
          font-weight: bold;
          font-size: 1.5rem;
        }
        &:last-child {
          min-height: 12rem;
        }
        list-style: none;
        margin-top: 1rem;
      }
      @include media-breakpoint-down(md) {
        li {
          &:first-child {
            font-size: 1.375rem;
          }
          &:last-child {
            font-size: 1rem;
          }
        }
      }
    }
    @include media-breakpoint-down(sm) {
      .carousel-control-prev,
      .carousel-control-next {
        position: absolute;
        top: 100%;
        img {
          width: 3rem;
        }
        margin-top: 1rem;
      }
      .carousel-control-next {
        padding-right: 5rem;
      }
      .carousel-control-prev {
        padding-left: 5rem;
      }
      .carousel-inner {
        margin-left: auto;
        margin-right: auto;
        width: 70%;
      }

      ul {
        padding: 0;
      }

      .carousel-indicators {
        z-index: 0;
      }
    }
  }
}
.large-padding-y {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.visit-recap {
  #visit_story_photos {
    width: -webkit-fill-available;
  }
}
