.be-a-volunteer, .be-a-companion {
  @extend %sub-header-text;
  .intro {
    @include media-breakpoint-up(xl) {
      background-image: url('../images/www/be_a_companion/hero.jpg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .compliance {
    text-transform: uppercase;
    color: $light-black;
    font-weight: 600;
  }

  .friend-mate-pal .overlapping-images {
    min-height: 300px;

    @include media-breakpoint-up(sm) {
      min-height: 400px;
    }

    img {
      position: absolute;
      max-width: 100%;
    }
    img:first-child {
      top: 0;
      opacity: 0.9;
      left: 10px;
    }
    img:last-child {
      top: 10px;
      transform: rotateZ(-2deg);
    }
  }
}
