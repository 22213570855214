.blog {
  @extend %sub-header-text;
  .text {
    @include media-breakpoint-up(md) {
      padding: 3rem 5rem;
    }
  }
  .trix-content {
    color: $gray-text;
    h2 {
      font-family: $sf-pro;
      font-weight: 600;
      font-size: 3.125rem;
      @include media-breakpoint-down(md) {
        font-size: 2rem;
      }
    }
    h3 {
      font-weight: 600;
      font-size: 2rem;
      @include media-breakpoint-down(md) {
        font-size: 1.5rem;
      }
    }
  }
  .trix-content .attachment--preview {
    width: auto;
    text-align: center;
  }
}
