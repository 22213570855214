$green: #006468;
$off-green: #007d8c;
$card-margins: 24px;
$purple: #626fc7;

.resources {
  .intro {
    background-color: $green;
  }

  .navigation {

    .row {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .active {
        border-bottom: 5px solid $purple;
      }
      .col-3, .col-2, .col-4 {
        text-align: center;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        a {
          color: $purple;
        }
      }
    }
  }

  .card {
    margin-bottom: $card-margins;
    height: calc(100% - $card-margins);
    box-shadow: 0 3px 9px 0 rgb(89 89 89 / 23%);
    border: none;


    h2 {
      font-size: 1.25rem;
      font-family: $sf-pro;
    }

    .highlighted-card-wrapper {
      width: auto;
      height: 450px;
      overflow: hidden
    }

    .card-image-wrapper {
      height: 220px;
      overflow: hidden
    }

    .card-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }


    .link-text {
      color: $off-green;
    }
    .chevron-right {
      margin-left: 10px;
      width: 13px;
      height: 20px;
    }
  }
}
