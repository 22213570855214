.region {
  @extend %sub-header-text;
  @extend %companion-stories-carousel;

  .senior-care {
    min-height: 25rem;
    .trix-content div {
      text-align: left;
      width: 100%;
    }
    @include media-breakpoint-down(sm) {
      .col-md-6:nth-of-type(1) {
        order: 2;
      }
      .col-md-6:nth-of-type(2) {
        order: 1;
        margin-bottom: 3rem;
      }
    }
    @include media-breakpoint-down(md) {
      .inline-flex {
        display: block;
      }
    }
  }

  .senior-care-photo {
    img {
      width: auto;
      height: 20rem;
      @include media-breakpoint-down(md) {
        height: 13rem;
      }
    }
    .rotate {
      transform: rotate(-2deg);
    }
    .back-photo {
      img {
        margin-top: -1rem;
        margin-left: 0.5rem;
        position: absolute;
        z-index: -1;
      }
    }
  }

  .activity {
    li {
      list-style: none;
    }
    @include media-breakpoint-down(md) {
      .trix-content {
        ul {
          display: flex;
          flex-direction: column;
        }
        li {
          margin-bottom: 1rem;
        }
        ul li:nth-of-type(1) {
          order: 2;
        }
        ul li:nth-of-type(2) {
          order: 1;
          font-size: 1.25rem;
          color: #626fc7;
        }
        ul li:nth-of-type(3) {
          order: 3;
          font-size: 1.125rem;
        }
        .attachment--preview {
          text-align: left;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      h1 {
        padding-left: 50%;
      }
      .odd {
        img {
          transform: rotate(-2deg);
          margin-bottom: 1rem;
          margin-top: -4rem;
        }
      }
      .even {
        img {
          transform: rotate(2deg);
          margin-left: 2rem;
          margin-top: -3rem;
          z-index: 1;
          position:relative;
        }
      }
      .first {
        ul li:nth-of-type(1) {
          margin-top: 5rem;
        }
      }
      .trix-content {
        ul li:nth-of-type(1) {
          margin-right: 4rem;
          float: left;
          max-width: 100%;
        }
        ul li:nth-of-type(2) {
          font-size: 2.25rem;
          color: #626fc7;
        }
        ul li:nth-of-type(3) {
          font-size: 1.5rem;
          margin-right: 40rem;
          width: 100%;
        }
      }
      .btn {
        margin-left: 35rem;
        width: auto;
      }
    }
  }

  .local-companions {
    .trix-content {
      margin-left: auto;
      margin-right: auto;
    }
    @include media-breakpoint-down(md) {
      img {
        width: auto;
        max-height: 5rem;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
      figure {
        width: 80%;
        height: 5rem;
      }
      .carousel-item {
        text-align: center;
      }
    }
    background-color: #eaf3f1;
    .attachment-gallery {
      action-text-attachment {
        width: 20%;
      }
      .attachment {
        max-width: unset;
        @extend .center-vertically
      }
    }
  }
}
