.about-us {
  @extend %sub-header-text;
  h2 {
    font-size: 24px;
    font-family: Recoleta-Medium;
    color: #000000;

    @include media-breakpoint-up(lg) {
      line-height: 3.25rem;
    }

    @include media-breakpoint-down(md) {
      line-height: 2.5rem;
    }
  }

  h1 {
    font-family: Recoleta-Medium;
    font-size: 40px;
    color: #000000;
  }

  p {
    font-family: $sf-pro;
    font-size: 18px;
    color: #000000;
  }

  div.anchor-tag {
    scroll-margin-top: 50px;
  }

  .intro {
    p {
      color: $violet;
    }

    h1 {
      font-size: 30px;
    }
  }

  .team-photo {
    img {
      height: 29rem;
      width: auto;
      @include media-breakpoint-down(md) {
        height: 25rem;
      }
      @include media-breakpoint-down(sm) {
        height: 20rem;
      }
      @include media-breakpoint-down(xs) {
        height: 100%;
        width: 80%;
        object-fit: contain;
      }
    }
  }

  #how-we-are-different {
    h2 {
      @include media-breakpoint-up(lg) {
        line-height: 3.25rem;
      }

      @include media-breakpoint-down(md) {
        line-height: 2.5rem;
        font-size: 2rem !important
      }
    }

    p {
      color: #000000;
      @include media-breakpoint-up(lg) {
        font-size: 1.125rem;
        line-height: 1.75rem
      }

      @include media-breakpoint-down(md) {
        font-size: 1rem;
        line-height: 1.5rem
      }
    }

    img {
      @include media-breakpoint-up(lg) {
        width: 75%;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-top: 24px;
      }
      height: auto;
    }
  }

  .founding-team {
    .bio {
      @include media-breakpoint-down(md) {
        margin-bottom: 3rem;
      }
    }
    img {
      width: 14rem;
      height: 16rem;
    }
  }

  .movement {
    text-align: left;
  }

  .movement-photo {
    img {
      height: 30rem;
      width: auto;
      @include media-breakpoint-down(md) {
        height: 20rem;
      }
    }
  }
}
