$primary: #626fc7;
$secondary: #56ccf7;
$danger: #ff6674;
$gray: #9099a5;
$light-gray: #dbdfe4;
$dark-gray: #525960;
$gray-text: #6b6e7b;
$cloudy-blue: #bac2cc;
$goldenrod: #ffc20b;
$mon-ami-mint: #006468;
$sherbert: #faf4ee;
$frosting: #f7928e;
$toasted-sesame: #dee4f3;
$pitch-bg: rgba(212, 216, 242, 0.5);
$light-mint: #e8f4f2;
$violet: #5E6FCF;
$light-violet: #eaecf6;
$light-black: #083a3c;
$light-green: #eaf3f1;
$dark-blue: #232d6c;
$dark-green: #1a4b51;
$light-yellow: #f8f5be;
$placeholder: #DDDDDD;

$body-color: $dark-gray;
$headings-color: $dark-gray;
$body-bg: white;
$body-dark-bg: #f7f8f9;

$btn-link-disabled-color: $gray;

$link-color: $secondary;
$link-hover-color: #4a90e2;

$input-border: $light-gray;
$input-border-focus: $primary;
$input-border-focus: $primary;
$input-color-placeholder: $gray;

$btn-border-radius: 0px;

$header-line-height: 1;

$md-width: 768px !default;
$lg-width: 992px !default;

$sf-pro: -apple-system, BlinkMacSystemFont, Arial, sans-serif;
