.category, .landing-blog {
  @extend %sub-header-text;
  .header {
    background-image: url('../images/www/bg-blog-dark-purple.png');
    background-size: cover;
    .trix-content {
      color: white;
    }
  }
  .font-size-18 {
    color: #7f818d;
    a {
      color: $violet;
    }
  }
  .btn-secondary , .show > .btn-secondary.dropdown-toggle {
    color: $mon-ami-mint;
    background-color: white;
    border-color: #91939b;
  }
  .dropdown-toggle::after {
    color: $violet
  }
  .read {
    text-decoration: underline;
  }
  .by {
    flex-wrap: wrap;
  }
  .description {
    color: $gray-text;
  }
  h3 {
    a {
      color: $mon-ami-mint;
    }
  }
}