@import './www/variables';
@import './admin/org/bootstrap';
@import './www/base';
@import './www/trix_base';
@import './www/homepage';
@import './www/page';
@import './www/region';
@import './www/software-program';
@import './www/newsletter';
@import './www/ai';
@import './www/article';
@import './www/blog';
@import './www/category-blog';
@import './www/events';
@import './www/header';
@import './www/superform';

@import './www/static/be_a_volunteer';
@import './www/static/how_it_works';
@import './www/static/about_us';
@import './www/static/resources';

@import './bootstrap_v4.scss';

html {
  scroll-behavior: smooth;
}

.btn-primary, .btn-secondary, .btn-danger {
  padding: 0.6875rem 1.5rem;
  border-radius: 0.25rem;
  font-weight: bold;
}
