@import './variables';

.ai {
  h1 {
    color: black;
    font-weight: 500;
    font-family: Recoleta-Medium;

    @include media-breakpoint-up(lg) {
      font-size: 40px;
      line-height: 1.25;

    }

    @include media-breakpoint-down(md) {
      font-size: 40px;
      line-height: 1.25;
    }
  }

  h2 {
    color: black;
    font-weight: 700;
    font-size: 30px !important;
  }

  h3 {
    font-weight: 500;
    font-size: 20px !important;
    font-family: Recoleta-Medium;
  }

  p {
    font-size: 18px;
    font-family: $sf-pro;
  }

  .green {
    color: $mon-ami-mint;
  }

  .green-background {
    background-color: #EFF5F5;
  }

  .gold {
    color: #C39900;
  }

  .gold-background {
    background-color: $sherbert;
  }

  .background-element {
    background-image: url('../images/www/ai/background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }


  .btn-secondary {
    color: white;
    background-color: $mon-ami-mint;
    border-color: $mon-ami-mint;
  }

  .btn-secondary:hover {
    color: $mon-ami-mint;
    background-color: #A7C4C5;
    border-color: #A7C4C5;
  }

  .max-width {
    max-width: 1440px;
  }
}
