/**
 * @license
 * MyFonts Webfont Build ID 3860883, 2020-01-28T14:54:54-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Recoleta-Regular by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/recoleta/regular/
 * Copyright: Copyright &#x00A9; 2018 by Jorge Cisterna. All rights reserved.
 * Licensed pageviews: 10,000
 *
 *
 *
 * © 2020 MyFonts Inc
*/

@font-face {
  font-family: 'Recoleta-Regular';
  src:
    url('https://monami-www-fonts.s3.us-east-2.amazonaws.com/3AE993_0_0.woff2') format('woff2'),
    url('https://monami-www-fonts.s3.us-east-2.amazonaws.com/3AE993_0_0.woff') format('woff'),
    url('https://monami-www-fonts.s3.us-east-2.amazonaws.com/3AE993_0_0.ttf') format('truetype'),
    local('Georgia, Regular');
  font-display: swap;
}

@font-face {
  font-family: 'Recoleta-Medium';
  src:
    url('https://monami-www-fonts.s3.us-east-2.amazonaws.com/RecoletaMedium/font.woff2') format('woff2'),
    url('https://monami-www-fonts.s3.us-east-2.amazonaws.com/RecoletaMedium/font.woff') format('woff'),
    local('Arial');
  font-display: Georgia, Bold;
}

