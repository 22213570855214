.how-it-works {
  @extend %sub-header-text;

  .activities {
    margin-top: -5rem;
    padding-top: 7rem;

    ul {
      list-style: none;

      li {
        @extend .py-1;
      }
    }
  }

  .images-carousel {
    max-width: 1500px;
    margin: 0 auto;

    .rotate-z {
      transform: rotateZ(-2deg);
    }

    .position-inherit {
      position: inherit;
    }

    .scale-3d {
      transform: scale3d(1.15, 1.15, 1.15);
    }

    img {
      max-width: 100%;
    }
  }
}
