.table > :not(:last-child) > :last-child > * {
  border-bottom: 1px solid #dadae3;
}

a {
  text-decoration: none;
}

.btn-primary {
  color: #fff;

  &:hover, &:active, &:focus, &:disabled {
    color: #fff;
  }
}

.btn-primary, .btn-secondary, .btn-danger {
  padding: 7px 1.5rem;
}

.btn-link {
  text-decoration: none;
  font-weight: medium;
  &:hover {
    text-decoration: underline
  }
}

.container {
  max-width: 1140px;
}

//could move this to simple forms and react components
.form-group {
  margin-bottom: 1rem;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(23,17,35,.1);
  opacity: 0.1;
}

.form-control-file {
  display: block;
}
