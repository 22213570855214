// Customized version of https://github.com/twbs/bootstrap/blob/main/scss/bootstrap.scss
//
@import "~bootstrap/scss/mixins/banner";
@include bsBanner("");

// Set spacer definitions before we generate utilites.
//
// IMPORTANT: This must come before we import `mixins` and `utilities`
//
// NOTE: I don't like that we hacked this and it's a total departure from Bootstrap. - Craig McNamara
//
$spacer: 1rem;
$spacers: ( 0: 0 );
$i: 1;
@while $i <= 30 {
  $spacers: map-merge(
    (
      $i: ($spacer * 0.25 * $i)
    ),
    $spacers
  );
  $i: $i + 1;
}

// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";

html {
  font-size: 16px !important;
}

// This is a hack until we land this and fix our bootstrap config.
//
// https://github.com/mes-amis/monami/pull/17351
//
.rounded-4 {
  border-radius: 1rem;
}
